<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="현장경영 스케줄">
            <div class="btn-ibbox title-btnbox float-right">
                <button class="btn-default">현황보기</button>
            </div>
            - <span class="txt-s1">검정색글씨</span> : 외근 예정자<br>
            - <span class="txt-s2">하늘색글씨</span> : 고객사일지 작성자<br>
            - <span class="txt-s3">주황색글씨</span> : 고객사일지 미작성자
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div class="day-select mt-40">
                        <button class="btn btn-prev">prev</button>
                        <button class="day btn-cal">2019-07-12 ~ 2019-07-18</button>
                        <button class="btn btn-next">next</button>
                    </div>
                    <div class="mt-40 mb-20">
                        <select name="" id="" class="w-200px mr-5">
                            <option value=""> 스케줄 선택</option>
                        </select>
                        <CarrotDept></CarrotDept>
                        <CarrotTeam></CarrotTeam>
                        <router-link :to="{ name:'customerManagement-scheduleOffSiteAdd' }"><button class="btn-default float-right">스케줄 등록</button></router-link>
                        <div class="clear"></div>
                    </div>
                    <table class="table-col table-cal">
                        <colgroup>
                            <col span="7">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>SUN</th>
                                <th>MON</th>
                                <th>TUE</th>
                                <th>WED</th>
                                <th>THU</th>
                                <th>FRI</th>
                                <th>SAT</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>08/18</th>
                                <th>08/19</th>
                                <th>08/20</th>
                                <th>08/21</th>
                                <th>08/22</th>
                                <th>08/23</th>
                                <th>08/24</th>
                            </tr>
                            <tr>
                                <td>
                                    <p class="txt-s2">박소희 : 삼성물산</p>
                                </td>
                                <td>
                                    <p class="txt-s2">최종현 : SK하이</p>
                                    <p class="txt-s1">장민지 : 부산광역</p>
                                    <p class="txt-s1">박소희 : 롯데케미</p>
                                    <p class="txt-s1">최현진 : 삼성전자</p>
                                    <p class="txt-s1">박송이 : 엘지유플</p>
                                    <p class="txt-s1">김도엽 : 흥국화재</p>
                                    <p class="txt-s1">전유미 : 에이치이</p>
                                    <p class="txt-s1">조연아 : 삼성에스</p>
                                    <p class="txt-s3">김명준 : 에이치에</p>
                                    <p class="txt-s3">김예주 : SK하이</p>
                                    <p class="txt-s2">이민지 : SK하이</p>
                                </td>
                                <td>
                                    <p class="txt-s1">박소희 : 삼성물산</p>
                                    <p class="txt-s1">박소희 : 삼성물산</p>
                                    <p class="txt-s3">박소희 : 삼성물산</p>
                                    <p class="txt-s2">박소희 : 삼성물산</p>
                                    <p class="txt-s2">박소희 : 삼성물산</p>
                                </td>
                                <td>
                                    <p class="txt-s1">박소희 : 삼성물산</p>
                                    <p class="txt-s3">박소희 : 삼성물산</p>
                                    <p class="txt-s2">박소희 : 삼성물산</p>
                                </td>
                                <td>
                                    <p class="txt-s1">박소희 : 삼성물산</p>
                                    <p class="txt-s1">박소희 : 삼성물산</p>
                                    <p class="txt-s1">박소희 : 삼성물산</p>
                                    <p class="txt-s1">박소희 : 삼성물산</p>
                                    <p class="txt-s3">박소희 : 삼성물산</p>
                                    <p class="txt-s2">박소희 : 삼성물산</p>
                                </td>
                                <td>
                                    <p class="txt-s3">박소희 : 삼성물산</p>
                                </td>
                                <td class="sat">
                                    <p class="txt-s1">박소희 : 삼성물산</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- td > txt-s1~3 오버시 레이어 팝업 : @개발 calDetail-layer style은 임시로 위치 넣었습니다. 개발시 삭제하여 오버시 위치 넣어주세요. -->
                    <div class="calDetail-layer" style="display: block; position: absolute; top: 670px; left: 50%;">
                        <ul class="calDetail-info">
                            <li>매니저 : Yulia(김소연)</li>
                            <li>방문일시 : 2020-00-00  12:00</li>
                            <li>고객사 : SK하이닉스</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src


export default {
    layout:"customerManagement",
    components: {
    },
    setup() {
    }
}
</script>

<style lang="scss" scoped>
</style>